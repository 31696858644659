<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col xl="4" lg="4" md="4" sm="5" xs="12">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import _ from 'underscore'

    export default {
        beforeMount() {
            // Store query params
            let payload = {}

            if (typeof this.$route !== 'undefined' && typeof this.$route.query !== 'undefined') {
                if (typeof this.$route.query.p === 'string' && this.$route.query.p.length > 0) {
                    const parsed = parseInt(this.$route.query.p)
                    if (!isNaN(parsed)) {
                        payload.drc_patient_id = parsed
                    }
                }

                if (typeof this.$route.query.o === 'string' && this.$route.query.o.length > 0) {
                    const parsed = parseInt(this.$route.query.o)
                    if (!isNaN(parsed)) {
                        payload.drc_office_id = parsed
                    }
                }

                if (typeof this.$route.query.q === 'string' && this.$route.query.q.length > 0) {
                    const parsed = parseInt(this.$route.query.q)
                    if (!isNaN(parsed)) {
                      payload.office_id = parsed
                    }
                }

                if (typeof this.$route.query.v === 'string' && this.$route.query.v.length > 0) {
                  const parsed = parseInt(this.$route.query.v)
                  if (!isNaN(parsed)) {
                    payload.virtual_redirect = 1
                  }
                }

              if (typeof this.$route.query.z === 'string') {
                  payload.satellite_client = this.$route.query.z
              }

              if (typeof this.$route.query.f === 'string') {
                payload.flow = this.$route.query.f
              }
            }

            if (_.size(payload) > 0) {
                this.$store.commit('registration/update', payload)
            }
        }
    }
</script>
