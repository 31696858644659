<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">{{config.title}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-textarea readonly outlined :value="config.consentText" style="font-size: 14px"
                            height="350px"></v-textarea>
                <v-checkbox v-model="accepted" :label="config.checkboxText"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right" order="last">
                <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="accepted === false && allowContinueWithoutAccept === false">
                    Continue
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col order="first">
                <v-btn v-if="config.clickBack !== null" v-on:click.stop="config.clickBack()">
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'consent',
        data: function () {
            return {
                accepted: false,
                allowContinueWithoutAccept: false
            }
        },
        props: ['config'],
        beforeMount() {
            // Set value if stored
            const regStore = this.$store.getters['registration/get']
            if (typeof regStore[this.config.storeField] !== 'undefined') {
                this.accepted = regStore[this.config.storeField]
            }
            if (typeof this.config.allowContinueWithoutAccept !== 'undefined') {
            this.allowContinueWithoutAccept = this.config.allowContinueWithoutAccept
           }
        },
        mounted() {
            window.scrollTo(0, 0)
        },
        methods: {
            clickContinue: async function () {
                // Store result
                let payload = {}
                payload[this.config.storeField] = this.accepted
                await this.$http.post('/save', {current:this.$store.getters['registration/get'],payload:payload})
                this.$store.commit('registration/update', payload)

                // Execute callback click
                await this.config.clickContinue(payload)
            }
        }
    }
</script>
