<template>
    <v-app>
        <v-app-bar app dense><img src="logo-v2-600x200.png" height="40px"></v-app-bar>
        <v-main>
            <router-view></router-view>
        </v-main>
        <v-footer class="justify-center">© {{company_name}} {{getCurrentYear()}}</v-footer>
    </v-app>
</template>
<script>
    import moment from 'moment'

    export default {
        data: function () {
            return {
                company_name: process.env.VUE_APP_COMPANY_NAME
            }
        },
        beforeMount: function() {
          const routes = this.$http

          console['log'] = function(message){
            routes.post('/log',{type:'log',message:message})
          }

          console['info'] = function(message){
            routes.post('/log',{type:'info',message:message})
          }

          console['error'] = function(message){
            routes.post('/log',{type:'error',message:message})
          }

          console['warn'] = function(message){
            routes.post('/log',{type:'warn',message:message})
          }
        },
      methods: {
            getCurrentYear() {
                return moment().format('YYYY')
            }
        }
    }
</script>
