<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Please answer the following...',
        fields: [
          {
            display_long: 'Have you been thinking about how you might kill yourself?',
            name: 'cssrs_3',
            type: 'select',
            items: [
              'No',
              'Yes - within the past month'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Have you had these thoughts and had some intention of acting on them?',
            name: 'cssrs_4',
            type: 'select',
            items: [
              'No',
              'Yes - within the past month'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Have you started to work out or worked out the details of how to kill yourself? Did you intend to carry out this plan?',
            name: 'cssrs_5',
            type: 'select',
            items: [
              'No',
              'Yes - within the past month'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Have you ever done anything, started to do anything, or prepared to do anything to end your life?',
            name: 'cssrs_6',
            type: 'select',
            items: [
              'No',
              'Yes'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'bh_background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function (value) {
          const immediateDanger = value.bh_background_cssrs_4 !== 'No' || value.bh_background_cssrs_5 !== 'No' || value.bh_background_cssrs_6 !== 'No'
          if (immediateDanger === true) {
            await this.$http.post('/sendCrisisNotification', {payload: this.registration})
          }
          // Submit flow payload if we are in valid flow
          if (this.flow) {
            // Submit payload
            await this.$http.post('/registration', this.registration)
            this.$store.commit('registration/clear')
            this.$router.push({path: '/reg/complete', query: {f: this.flow}})
          } else {
            await this.$router.push('/reg/Conditions')
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
    this.flow = this.$store.getters['registration/get'].flow
    this.registration = this.$store.getters['registration/get']
  },
}
</script>
