<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">{{config.question}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-form v-model="isValid" @submit.prevent="">
                    <h4 v-if="config.helpText">{{config.helpText}}</h4>
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                            offset-y
                            max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateFormatted" placeholder="MM/DD/YYYY" prepend-icon="mdi-calendar"
                                          v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on"
                                          :rules="rules"></v-text-field>
                        </template>
                        <v-date-picker ref="picker" v-model="date" no-title @input="menu = false" :min="config.minDate"
                                       :max="config.maxDate"></v-date-picker>
                    </v-menu>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right" order="last">
                <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="!isValid">
                    Continue
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col order="first">
                <v-btn v-if="config.clickBack !== null" v-on:click.stop="config.clickBack()">
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'date',
        data: function () {
            return {
                isValid: false,
                date: null,
                dateFormatted: this.formatDate(this.date),
                menu: false,
                rules: [
                    v => !!v || 'Required',
                    v => moment(v, 'MM/DD/YYYY', true).isValid() || 'Expected date format MM/DD/YYYY',
                    v => ((this.config.maxDate === undefined || this.config.maxDate === null)
                        || moment(v, 'MM/DD/YYYY', true).isSameOrBefore(moment(this.config.maxDate))
                        || `Must be on or before ${moment(this.config.maxDate).format('MM/DD/YYYY')}`),
                    v => ((this.config.minDate === undefined || this.config.minDate === null)
                        || moment(v, 'MM/DD/YYYY', true).isSameOrAfter(moment(this.config.minDate))
                        || `Must be on or after ${moment(this.config.minDate).format('MM/DD/YYYY')}`),
                ]
            }
        },
        props: ['config'],
        beforeMount() {
            // Set value if stored
            const regStore = this.$store.getters['registration/get'];
            if (typeof regStore[this.config.storeField] !== 'undefined') {
                this.date = regStore[this.config.storeField]
            }
        },
        mounted() {
            window.scrollTo(0, 0)
        },
        watch: {
            date: function () {
                this.dateFormatted = this.formatDate(this.date)
            },
            menu(val) {
                if (this.config.birthdayPicker === true) {
                    val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
                } else {
                    val
                }
            }
        },
        methods: {
            formatDate: function (date) {
                if (!date) {
                    return null
                }
                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate: function (date) {
                if (!date) {
                    return null
                }

                const tmpDate = moment(date, ['MM/DD/YYYY', 'M/D/YYYY'], true)

                if (tmpDate.isValid() === true) {
                    return tmpDate.format('YYYY-MM-DD')
                } else {
                    return null
                }
            },
            clickContinue: async function () {
                // Store result
                let payload = {};
                payload[this.config.storeField] = this.date;
                await this.$http.post('/save', {current:this.$store.getters['registration/get'],payload:payload})
                this.$store.commit('registration/update', payload);

                // Execute callback click
                await this.config.clickContinue(this.date);
            }
        }
    }
</script>
