<template>
  <consent :config="config" :key="config.storeField"></consent>
</template>
<script>
import Consent from '../template/Consent';
import ConsentText from '../../assets/consents/medicare.txt'

export default {
  components: {Consent},
  data: function () {
    return {
      config: {
        title: 'Confirm Your Primary Care Provider',
        consentText: ConsentText,
        checkboxText: 'I confirm that Total Primary Care is my Primary Care Provider',
        storeField: 'accepted_consent_medicare',
        allowContinueWithoutAccept: true,
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/medicalRecordsRelease')
        }.bind(this)
      }
    }
  }
}
</script>
