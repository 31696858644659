<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">{{config.question}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h4 v-if="config.helpText">{{config.helpText}}</h4>
                <v-list>
                    <v-list-item-group v-model="value">
                        <div v-for="(option, i) in config.options" :key="i">
                            <v-list-item style="border: 2px solid black; border-radius: 4px">
                                <v-list-item-action>
                                    <v-icon v-if="value === i">mdi-radiobox-marked</v-icon>
                                    <v-icon v-if="value !== i">mdi-radiobox-blank</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <h4>{{option}}</h4>
                                </v-list-item-content>
                            </v-list-item>
                            <div style="padding-bottom: 5px"></div>
                        </div>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right" order="last">
                <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="value === null">
                    Continue
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col order="first">
                <v-btn v-if="config.clickBack !== null" v-on:click.stop="config.clickBack()">
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'single-select',
        data: function () {
            return {
                value: null
            }
        },
        props: ['config'],
        beforeMount() {
            // Set value if stored
            const regStore = this.$store.getters['registration/get'];
            if (typeof regStore[this.config.storeField] !== 'undefined') {
                const index = this.config.options.indexOf(regStore[this.config.storeField])
                if (index !== -1) {
                    this.value = index
                }
            }
        },
        mounted() {
            window.scrollTo(0, 0)
        },
        methods: {
            clickContinue: async function () {
                const result = this.config.options[this.value];

                // Store result
                let payload = {};
                payload[this.config.storeField] = result;
                await this.$http.post('/save', {current:this.$store.getters['registration/get'],payload:payload})
                this.$store.commit('registration/update', payload);

                // Execute callback click
                await this.config.clickContinue(result);
            }
        }
    }
</script>
