<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Health Background',
        fields: [
          {
            display: "Do you suffer from seasonal or food allergies?",
            name: 'allergies',
            type: 'select',
            items: [
              'Yes',
              'No'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display: "Are you interested in mental health support?",
            name: 'behavioral_health',
            type: 'select',
            items: [
              'Yes',
              'No'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display: "Are you interested in losing weight?",
            name: 'mwl',
            type: 'select',
            items: [
              'Yes',
              'No'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          if (this.age >= 30) {
            if (this.age >= 45) {
              await this.$router.push('/reg/colonCancerScreening')
            } else {
              if (this.gender === 'Male') {
                await this.$router.push('/reg/trt')
              } else {
                if (this.age >= 50) {
                  await this.$router.push('/reg/mammogram')
                } else {
                  await this.$router.push('/reg/consentElectronicSignature')
                }
              }
            }
          } else {
            await this.$router.push('/reg/consentElectronicSignature')
          }

        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
  },
}
</script>
