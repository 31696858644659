<template>
    <div>
        <v-form v-model="isValid" @submit.prevent="">
            <v-row>
                <v-col>
                    <h3 class="font-weight-bold">{{config.question}}</h3>
                </v-col>
            </v-row>
            <v-row v-if="config.helpText">
                <v-col>
                    <h4>{{config.helpText}}</h4>
                </v-col>
            </v-row>
            <v-row v-for="field of fields" v-bind:key="field.name" dense>
                <v-col v-if="field.type === 'select'">
                    <h4 v-if="field.display_long" class="font-weight-regular">{{field.display_long}}</h4>
                    <v-select v-model="field.value" :items="field.items" :label="field.display"
                              :rules="field.rules"></v-select>
                </v-col>
                <v-col v-else>
                    <v-text-field :label="field.display" v-model="field.value" :rules="field.rules"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="config.bypassText">
                <v-col>
                    <v-checkbox v-model="bypass" class="align-center justify-center"
                                :label="config.bypassText"></v-checkbox>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-right" order="last">
                    <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="!isValid && !bypass">
                        Continue
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col order="first">
                    <v-btn v-if="config.clickBack !== null" v-on:click.stop="config.clickBack()">
                        Back
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    export default {
        name: 'text-field',
        data: function () {
            return {
                fields: [],
                isValid: false,
                bypass: false,
            }
        },
        props: ['config'],
        beforeMount() {
            // Load options
            for (const i of this.config.fields) {
                this.fields.push({
                    display: i.display,
                    display_long: i.display_long,
                    name: i.name,
                    value: null,
                    rules: i.rules ? i.rules : [],
                    items: i.items ? i.items : [],
                    type: i.type,
                });
            }

            // Set value if stored
            const regStore = this.$store.getters['registration/get'];
            for (const i of this.fields) {
                if (typeof regStore[`${this.config.storeField}_${i.name}`] !== 'undefined') {
                    i.value = regStore[`${this.config.storeField}_${i.name}`]
                }
              }
        },
        mounted() {
            window.scrollTo(0, 0)
        },
        methods: {
            clickContinue: async function () {
                // Store result
                let payload = {};
                for (const i of this.fields) {
                  // Normal field
                    if (this.bypass === true) {
                      payload[`${this.config.storeField}_${i.name}`] = '';
                    }
                    else {
                      payload[`${this.config.storeField}_${i.name}`] = typeof i.value === 'string' ?
                          i.value.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "") :
                          i.value;
                    }
                  }
                await this.$http.post('/save', {current:this.$store.getters['registration/get'],payload:payload})
                this.$store.commit('registration/update', payload);

                // Execute callback click
                await this.config.clickContinue(payload);
            }
        }
    }
</script>
