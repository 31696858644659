export default {
    namespaced: true,
    state: {
        data: {}
    },
    getters: {
        get(state) {
            //console.log('get state:%s', JSON.stringify(state.data))
            return state.data;
        }
    },
    mutations: {
        update(state, payload) {
            //console.log('update state:%s payload:%s', JSON.stringify(state.data), JSON.stringify(payload))
            for (const i in payload) {
                state.data[i] = payload[i]
            }
        },
        clear(state) {
            //console.log('clear:%s', JSON.stringify(state.data))
            for (const i in state.data) {
                delete state.data[i]
            }
            //console.log('clear done:%s', JSON.stringify(state.data))
        }
    }
}