<template>
    <photos :config="config" :key="config.storeField"></photos>
</template>
<script>
    import Photos from '../template/Photos';

    export default {
        components: {Photos},
        data: function () {
            return {
                config: {
                    question: 'Upload photos of your health insurance card',
                    storeField: 'insurance_photos',
                    showBackOption: true,
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/insurance')
                    }.bind(this)
                }
            }
        }
    }
</script>
