<template>
    <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
    import SingleSelect from '../template/SingleSelect'

    export default {
        components: {SingleSelect},
        data: function () {
            return {
                config: {
                    question: 'What is your race?',
                    options: [
                        'American Indian or Alaska Native',
                        'Asian',
                        'Black or African American',
                        'Native Hawaiian or other Pacific Islander',
                        'White',
                        'Other',
                        'Prefer not to share'
                    ],
                    storeField: 'race',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/ethnicity')
                    }.bind(this)
                }
            }
        }
    }
</script>
