<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">Summary</h3>
            </v-col>
        </v-row>
        <div v-if="basic.length > 0">
            <v-row>
                <v-col>
                    <h4>Basic Information</h4>
                    <div v-for="item of basic" v-bind:key="item.field" class="text-body-2">
                        <strong>{{item.field}}</strong>: {{item.value}}<br></div>
                </v-col>
            </v-row>
        </div>
        <div v-if="demographics.length > 0">
            <v-row>
                <v-col>
                    <h4>Demographics</h4>
                    <div v-for="item of demographics" v-bind:key="item.field" class="text-body-2">
                        <strong>{{item.field}}</strong>:
                        {{item.value}}<br></div>
                </v-col>
            </v-row>
        </div>
        <div v-if="insurance.length > 0">
            <v-row>
                <v-col>
                    <h4>Insurance</h4>
                    <div v-for="item of insurance" v-bind:key="item.field" class="text-body-2">
                        <strong>{{item.field}}</strong>: {{item.value}}<br>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-row>
            <v-col>
                <v-btn v-on:click.stop="clickBack()">
                    Back
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right">
                <v-btn color="primary" v-on:click.stop="clickSubmit()" :loading="isLoading" :disabled="isLoading">
                    Submit
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import moment from 'moment'
    import _ from 'underscore'

    export default {
        data: function () {
            return {
                registration: null,
                basic: [],
                demographics: [],
                insurance: [],
                virtual_redirect: false,
                isLoading: false,
            }
        },
        beforeMount() {
            this.registration = this.$store.getters['registration/get']
        },
        mounted() {

            for (const key in this.registration) {
                if (key === 'name_first') {
                    this.basic.push({
                        field: 'Name',
                        value: `${this.registration[key]} ${this.registration['name_last']}`
                    })
                }
                if (key === 'date_of_birth') {
                    this.basic.push({
                        field: 'Date of Birth',
                        value: moment(this.registration[key]).format('M/D/YYYY')
                    })
                }
                if (key === 'address_address') {
                    this.basic.push({
                        field: 'Address',
                        value: `${this.registration[key]}, ${this.registration['address_zip_code']}`
                    })
                }
                if (key === 'email_email') {
                    this.basic.push({
                        field: 'Email',
                        value: this.registration[key]
                    })
                }
                if (key === 'gender') {
                    this.demographics.push({
                        field: 'Gender',
                        value: this.registration[key]
                    })
                }
                if (key === 'race') {
                    this.demographics.push({
                        field: 'Race',
                        value: this.registration[key]
                    })
                }
                if (key === 'ethnicity') {
                    this.demographics.push({
                        field: 'Ethnicity',
                        value: this.registration[key]
                    })
                }
                if (key === 'insurance_name') {
                    this.insurance.push({
                        field: 'Company',
                        value: this.registration[key]
                    })
                }
                if (key === 'insurance_id') {
                    this.insurance.push({
                        field: 'Member ID',
                        value: this.registration[key]
                    })
                }
                if (key === 'insurance_group') {
                    this.insurance.push({
                        field: 'Group',
                        value: this.registration[key]
                    })
                }
                if (key === 'virtual_redirect') {
                  if(this.registration[key] === 1){
                    this.virtual_redirect = true
                  }
                  delete this.registration[key]
                }
            }
        },
        methods: {
            clickBack: function () {
                this.$router.go(-1)
            },
            clickSubmit: _.debounce(async function () {
                if (this.isLoading === false) {
                    try {
                        this.isLoading = true

                        // Submit registration
                        await this.$http.post('/registration', this.registration)

                        // Submit done
                        this.isLoading = false
                        this.$store.commit('registration/clear')

                        if (this.virtual_redirect === true) {
                          this.$router.push({path: '/reg/complete', query: {virtual: 1}})
                        } else {
                          this.$router.push('/reg/complete')
                        }
                    } catch (e) {
                        this.isLoading = false
                    }
                }
            }, 100)
        }
    }
</script>
