<template>
  <div>
    <v-row v-if="this.flow">
      <v-col align="center" justify="center">
        <h3 class="font-weight-bold">Thank you for completing your patient forms!</h3>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col align="center" justify="center">
        <h3 class="font-weight-bold">Thank you for completing your patient registration!</h3>
      </v-col>
    </v-row>
    <div v-if="this.virtual === true">
      <v-row>
        <v-col align="center" justify="center">
          <v-btn color="primary" href="https://total.doxy.me/afterhours" v-if="this.virtual === true">
            Join The Waiting Room
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
    export default {
        data: function () {
          return {
            virtual: false,
            flow: false
          }
        },
        beforeMount() {
          this.virtual = this.$route.query.virtual === '1'
          if(typeof(this.$route.query.f) !== 'undefined'){
            this.flow = true
          }
        },
        beforeRouteLeave(to, from, next) {
          if (from.path === '/reg/complete' && to.path === '/reg/summary') {
            next('/')
          } else {
            next()
          }
        }
    }
</script>
