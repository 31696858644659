<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Background Information',
        fields: [
          {
            display: 'Reason for today’s visit?',
            name: 'reason',
            rules: [
              v => !!v || 'Required',
              v => !v || v.length <= 255 || 'Maximum of 255 characters is allowed',
            ]
          },
          {
            display: 'Last annual wellness exam?',
            name: 'last_annual',
            type: 'select',
            items: [
              'Less than 1 year ago',
              'More than 1 year ago',
              'I do not remember',
              'Never'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display: 'Last visit with a healthcare provider?',
            name: 'last_healthcare_visit',
            type: 'select',
            items: [
              'In the past 6 months',
              'In the past year',
              'In the past 3 years',
              'In the past 6 years',
              'In the past 10 years',
              'I do not remember',
              'Never'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display: 'Last dental exam?',
            name: 'last_dental',
            type: 'select',
            items: [
              'Less than 6 months ago',
              'More than 6 months ago',
              'I do not remember',
              'Never'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display: 'How did you hear about us?',
            name: 'hear_about_us',
            type: 'select',
            items: [
              'Facebook',
              'Google',
              'Insurance Referral',
              'Mailer',
              'Personal Referral',
              'Radio',
              'Signage',
              'TV',
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          if (this.age >= 18) {
            await this.$router.push('/reg/ssBackground')
          } else {
            await this.$router.push('/reg/consentElectronicSignature')
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
  },
}
</script>
