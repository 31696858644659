<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col xl="4" lg="4" md="4" sm="5" xs="12">
                <!-- Verify -->
                <v-form v-if="verifiedStatus === 'not-verified'" v-model="isVerifyFormValid" @submit.prevent="">
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Verify your identity</h3>
                            <h4 class="text-subtitle-1">For your privacy and security, we need to confirm your identity
                                before showing your appointment. Please enter your last name and date of birth
                                below.</h4>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Last Name" v-model="lastName" :rules="lastNameRules"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateOfBirthFormatted" label="Date of birth (MM/DD/YYYY)"
                                                  v-bind="attrs" @blur="dateOfBirth = parseDate(dateOfBirthFormatted)"
                                                  v-on="on" :rules="dateOfBirthRules"></v-text-field>
                                </template>
                                <v-date-picker ref="picker" v-model="dateOfBirth" no-title
                                               @input="dateOfBirthMenu = false" :min="dateOfBirthMin"
                                               :max="dateOfBirthMax"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-right" order="last">
                            <v-btn color="primary" v-on:click="clickVerify()"
                                   :disabled="!isVerifyFormValid || isVerifyLoading" :loading="isVerifyLoading">
                                Continue
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <!-- End Verify -->
                <!-- Verify Failed -->
                <div v-if="verifiedStatus === 'error'">
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Error</h3>
                            <h4 class="text-subtitle-1">There was an error in verifying the information you provided.
                                Please text us back so we can try to resolve any verification issues.</h4>
                        </v-col>
                    </v-row>
                </div>
                <!-- End Verify Failed -->
                <!-- Link Expired -->
                <div v-if="verifiedStatus === 'expired'">
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Expired</h3>
                            <h4 class="text-subtitle-1">The link you have has expired.</h4>
                        </v-col>
                    </v-row>
                </div>
                <!-- End Link Expired -->
                <!-- Appointment -->
                <div v-if="verifiedStatus === 'verified'">
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Patient</h3>
                            <h4 class="text-subtitle-1">{{appointment.name}}</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Date of birth</h3>
                            <h4 class="text-subtitle-1">{{appointment.date_of_birth}}</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Clinic</h3>
                            <h4 class="text-subtitle-1">{{appointment.clinic}}</h4>
                            <h4 class="text-subtitle-1">{{appointment.clinic_address}}</h4>
                            <h4 class="text-subtitle-1">{{appointment.clinic_address2}}</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3 class="font-weight-bold">Appointment</h3>
                            <h4 class="text-subtitle-1">{{appointment.scheduled_time}}</h4>
                        </v-col>
                    </v-row>
                    <div v-if="typeof appointment.result_antigen !== 'undefined'">
                        <v-row>
                            <v-col>
                                <h3 class="font-weight-bold">COVID-19 Antigen</h3>
                                <span class="text-subtitle-1">
                                Result:
                                <v-chip color="red" text-color="white" v-if="appointment.result_antigen === 'positive'">POSITIVE</v-chip>
                                <v-chip color="green" text-color="white"
                                        v-if="appointment.result_antigen === 'negative'">NEGATIVE</v-chip>
                                <v-chip color="yellow" text-color="black"
                                        v-if="appointment.result_antigen === 'inconclusive'">INCONCLUSIVE</v-chip>
                            </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3 class="font-weight-bold">Antigen Information</h3>
                                <p v-if="appointment.result_antigen === 'positive'">
                                    Many patients experience mild to moderate symptoms similar to the flu. These can be
                                    managed with rest, hydration, and appropriate doses of fever-reducing medication. If
                                    at any time you experience chest pain, shortness of breath, difficulty breathing (if
                                    you can not speak a full sentence without taking a breath),
                                    confusion, vomiting that will not stop, or if you feel your symptoms are worsening
                                    in any way, please call 911 or go to your nearest emergency room.
                                    <br><br>
                                    Please isolate yourself and minimize any contact with others for at least 5 days, then
                                    wear a mask around anyone for 5 more days.
                                    Also notify anyone with whom you have been in close contact with in the past 7 days.
                                    They should consider getting tested.
                                </p>
                                <p v-if="appointment.result_antigen === 'negative'">
                                    Most likely you do not currently have an active COVID-19 infection. This test is a
                                    snapshot in time, it may take days after an exposure to develop an active infection.
                                    You should keep monitoring symptoms and may need to get retested if symptoms worsen.
                                    Take steps to protect yourself and others.
                                </p>
                                <p>For more information on your results - schedule a telemed appointment with one of our
                                    providers or visit: <a
                                            href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html">Here</a>
                                </p>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="typeof appointment.result_antibody !== 'undefined'">
                        <v-row>
                            <v-col>
                                <h3 class="font-weight-bold">COVID-19 Antibody</h3>
                                <span class="text-subtitle-1">
                                Result:
                                <v-chip color="red" text-color="white"
                                        v-if="appointment.result_antibody.igg === 'positive'">IGG: POSITIVE</v-chip>
                                <v-chip color="green" text-color="white"
                                        v-if="appointment.result_antibody.igg === 'negative'">IGG: NEGATIVE</v-chip>
                                <v-chip color="yellow" text-color="black"
                                        v-if="appointment.result_antibody.igg === 'inconclusive'">INCONCLUSIVE</v-chip>
                                    &nbsp;
                                <v-chip color="red" text-color="white"
                                        v-if="appointment.result_antibody.igm === 'positive'">IGM: POSITIVE</v-chip>
                                <v-chip color="green" text-color="white"
                                        v-if="appointment.result_antibody.igm === 'negative'">IGM: NEGATIVE</v-chip>
                                <v-chip color="yellow" text-color="black"
                                        v-if="appointment.result_antibody.igm === 'inconclusive'">INCONCLUSIVE</v-chip>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3 class="font-weight-bold">Antibody Information</h3>
                                <p v-if="appointment.result_antibody.igg === 'positive' || appointment.result_antibody.igm === 'positive'">
                                    You have been vaccinated for COVID-19, or you likely have had a COVID-19 infection.
                                    You may be protected from re-infection (have immunity), but this cannot be said with
                                    certainty.
                                </p>
                                <p v-if="appointment.result_antibody.igg === 'negative' && appointment.result_antibody.igm === 'negative'">
                                    You likely never had (or have not yet developed antibodies to) COVID-19 infection.
                                    You could still get COVID-19. Take steps to protect yourself and others. This test
                                    does not tell you if you currently have COVID-19. For that you will need an Antigen
                                    test.
                                </p>
                                <p>For more information on your results - schedule a telemed appointment with one of our
                                    providers or visit: <a
                                            href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html">Here</a>
                                </p>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="typeof appointment.pdf === 'string'">
                        <p>If you would like a copy of your results please click: <a :href="appointment.pdf" target="_blank" rel="noopener noreferrer">Here</a></p>
                    </div>
                </div>
                <!-- End Appointment -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import _ from 'underscore'

    export default {
        data: function () {
            return {
                appointmentId: null,
              dbAppointmentId: null,
                // not-verified, verified, error, expired
                verifiedStatus: 'not-verified',
                isVerifyFormValid: false,
                isVerifyLoading: false,
                lastName: null,
                lastNameRules: [
                    v => !!v || 'Required',
                    v => !v || v.length >= 2 || 'Minimum of 2 characters is allowed',
                    v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed'
                ],
                dateOfBirthMin: moment().subtract(100, 'years').format('YYYY-MM-DD'),
                dateOfBirthMax: moment().subtract(5, 'years').format('YYYY-MM-DD'),
                dateOfBirth: null,
                dateOfBirthFormatted: this.formatDate(this.dateOfBirth),
                dateOfBirthMenu: false,
                dateOfBirthRules: [
                    v => !!v || 'Required',
                    v => moment(v, 'MM/DD/YYYY', true).isValid() || 'Expected date format MM/DD/YYYY',
                    v => (moment(v, 'MM/DD/YYYY', true).isSameOrBefore(moment(this.dateOfBirthMax))
                      || `Must be on or before ${moment(this.dateOfBirthMax).format('MM/DD/YYYY')}`),
                    v => (moment(v, 'MM/DD/YYYY', true).isSameOrAfter(moment(this.dateOfBirthMin))
                      || `Must be on or after ${moment(this.dateOfBirthMin).format('MM/DD/YYYY')}`),
                ],
                appointment: null
            }
        },
        beforeMount() {
            if (typeof this.$route !== 'undefined' && typeof this.$route.params !== 'undefined') {
                this.appointmentId = this.$route.params.id
                this.dbAppointmentId = this.$route.params.db_id
            }
        },
        watch: {
            dateOfBirth: function () {
                this.dateOfBirthFormatted = this.formatDate(this.dateOfBirth)
            },
            dateOfBirthMenu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
        },
        methods: {
            formatDate: function (date) {
                if (!date) {
                    return null
                }
                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate: function (date) {
                if (!date) {
                    return null
                }

                const tmpDate = moment(date, ['MM/DD/YYYY', 'M/D/YYYY'], true)

                if (tmpDate.isValid() === true) {
                    return tmpDate.format('YYYY-MM-DD')
                } else {
                    return null
                }
            },
            clickVerify: _.debounce(async function () {
                try {
                    this.isVerifyLoading = true

                    // Submit request
                    const response = await this.$http.post('/appointments', {
                        id: this.appointmentId,
                        db_id: this.dbAppointmentId,
                        last_name: this.lastName,
                        date_of_birth: this.dateOfBirth
                    })
                    this.appointment = response.data.data
                    this.isVerifyLoading = false
                    this.verifiedStatus = 'verified'

                } catch (e) {
                    this.verifiedStatus = 'error'
                    if (typeof e.response !== 'undefined' && typeof e.response.data !== 'undefined' && e.response.data.code === 'expired') {
                        this.verifiedStatus = 'expired'
                    }
                    this.isVerifyLoading = false
                }
            }, 100)
        }
    }
</script>
