<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';
import moment from "moment";

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: "Would you like to receive preventative screenings when you're due?",
        helpText: "Receiving the preventative screenings will be of no cost to you, and we'll use the information you provided to ship it to you. 94% of patients are fully covered by insurance, and we will provide information about how to determine whether your insurance covers it.",
        options: [
          'Yes',
          'No'
        ],
        storeField: 'preventative_screenings',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          if (this.gender === 'Male') {
            if (this.age >= 30) {
              await this.$router.push('/reg/trt')
            } else {
              await this.$router.push('/reg/consentElectronicSignature')
            }
          } else {
            if (this.age >= 50) {
              await this.$router.push('/reg/mammogram')
            } else {
              await this.$router.push('/reg/consentElectronicSignature')
            }
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
  },
}
</script>
