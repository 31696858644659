<template>
    <multi-select :config="config"></multi-select>
</template>
<script>
    import MultiSelect from '../template/MultiSelect';

    export default {
        components: {MultiSelect},
        data: function () {
            return {
                config: {
                    question: 'Specified Individual Records Release:',
                    helpText: 'Disclose my health record, but do NOT disclose the following...',
                    options: [
                        'Mental health records',
                        'Communicable diseases (including HIV and AIDS)',
                        'Alcohol/drug abuse treatment',
                    ],
                    fields: [
                      {
                        display: 'Other - please specify',
                        name: 'other',
                        rules: [
                          v => !!v || 'Required',
                        ]
                      }
                    ],
                    storeField: 'medical_records_release_authorization_restrictions',
                    clickBack: this.clickBack,
                    clickContinue: this.clickContinue
                }
            }
        },
        methods: {
            clickBack: function () {
                this.$router.go(-1)
            },
            clickContinue: function () {
                // Change to next page
                this.$router.push('/reg/medicalRecordsReleaseStep4')
            }
        }
    }
</script>
