<template>
  <div>
    <text-field :config="config" :key="config.storeField"></text-field>
    <v-row v-if="this.retryError === true">
      <v-col>
        <h4 class="red--text">There has been an internal error, please click the continue button to attempt at resending
          your code</h4>
      </v-col>
    </v-row>
    <v-row v-if="this.maxAttempts === true">
      <v-col>
        <h4 class="red--text">You have reached the maximum attempts for verification - wait 10 minutes and try again, or
          please fill out the new patient kiosk in the lobby</h4>
      </v-col>
    </v-row>
    <v-row v-if="this.smsNotSupported === true">
      <v-col>
        <h4 class="red--text">You have entered a phone number that does not support SMS messages. Please try a different number</h4>
      </v-col>
    </v-row>
  </div>
</template>
<script>
    import TextField from '../template/TextField';
    import PhoneNumber from 'awesome-phonenumber';

    export default {
      components: {TextField},
      data: function () {
        return {
          retryError: false,
          maxAttempts: false,
          smsNotSupported:false,
          config: {
            question: 'What is your mobile phone number?',
            helpText: 'Please enter a phone number that can receive SMS messages. Text message frequency varies. Message and data rates may apply.',
            fields: [
              {
                display: 'Phone Number',
                name: 'phone',
                rules: [
                  v => !!v || 'Required',
                  v => !v || ((PhoneNumber(v, 'US').isValid() === true) && (PhoneNumber(v, 'US').isPossible() === true)) || 'Please enter a valid phone number'

                ]
              }
            ],
            storeField: 'phone',
            clickBack: function () {
              this.$router.go(-1)
            }.bind(this),
            clickContinue: async function () {
              const phoneNumber = PhoneNumber(this.$store.getters['registration/get'].phone_phone, 'US').getNumber()
              // Skip SMS verification if we are in flow or we flipped killswitch in config
              if (typeof (this.flow) !== 'undefined' || process.env.VUE_APP_SKIP_SMS_PIN_VERIFY === 'true') {
                await this.$router.push('/reg/email')
              } else {
                try {
                  await this.$http.post('/sendPin', {phone: `${phoneNumber}`})
                  await this.$router.push('/reg/verifyPhone')
                } catch (e) {
                  if (e.response.status === 429) {
                    this.maxAttempts = true
                  } else if (e.response.status === 403) {
                    this.smsNotSupported = true
                  } else {
                    this.retryError = true
                  }
                }
              }
            }.bind(this)
          }
        }
      },
      beforeMount() {
        this.flow = this.$store.getters['registration/get'].flow
      }
    }
</script>
