<template>
  <multi-select :config="config" :key="config.storeField"></multi-select>
</template>
<script>
import MultiSelect from '../template/MultiSelect';

export default {
  components: {MultiSelect},
  data: function () {
    return {
      config: {
        question: 'Have you been diagnosed with any of the following conditions?',
        helpText: 'Please check all that apply:',
        options: [
          'Arthritis',
          "Alzheimer's disease",
          'Breast cancer',
          'Colorectal cancer',
          'Chronic respiratory disease (asthma, COPD, lung cancer, pulmonary fibrosis, pneumonia)',
          'Diabetes',
          'Hypertension (high blood pressure)',
          'Hyperlipidemia (high cholesterol)',
          'Other heart-related condition',
          'Kidney disease',
          'Pancreatic cancer'
        ],
        onlyOption: 'None of the above',
        fields: [
          {
            display: 'Other - please specify',
            name: 'other',
            rules: [
              v => !!v || 'Required',
            ]
          }
        ],
        storeField: 'conditions',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/programs')
        }.bind(this)
      }
    }
  }
}
</script>
