import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

const vuexLocal = new VuexPersistence({
    storage: localforage,
    asyncStorage: true
})

import registration from "./modules/registration"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        registration
    },
    plugins: [vuexLocal.plugin],
    strict: true
})
