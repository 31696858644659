<template>
    <div>
        <v-row>
            <v-col>
                <h3 class="font-weight-bold">{{config.question}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h4 v-if="config.helpText">{{config.helpText}}</h4>
                <v-list>
                    <v-list-item-group multiple v-model="value">
                        <div v-for="(option, i) in options" :key="i">
                            <v-list-item style="border: 2px solid black; border-radius: 4px" :disabled="option.isDisabled"
                                         v-on:click.stop="selectOption(i)">
                                <v-list-item-action>
                                    <v-icon v-if="value.indexOf(i) !== -1">mdi-checkbox-marked</v-icon>
                                    <v-icon v-if="value.indexOf(i) === -1">mdi-checkbox-blank-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <h4>{{option.value}}</h4>
                                </v-list-item-content>
                            </v-list-item>
                            <div style="padding-bottom: 5px"></div>
                        </div>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <v-form v-model="isValid" @submit.prevent="">
          <v-row v-for="field of fields" v-bind:key="field.name" dense>
            <v-col>
              <v-text-field :label="field.display" v-model="field.value" :rules="field.rules"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
            <v-col class="text-right" order="last">
                <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="(isValid === false && value.length === 0) || (value.length === 0 && fields.length === 0)">
                    Continue
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col order="first">
                <v-btn v-if="config.clickBack !== null" v-on:click.stop="config.clickBack()">
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import _ from 'underscore';

    export default {
        name: 'multi-select',
        data: function () {
            return {
                fields: [],
                isValid: false,
                options: [],
                indexNone: null,
                value: []
            }
        },
        props: ['config'],
        beforeMount() {
            // Load options
            for (const i of this.config.options) {
                this.options.push({
                    isDisabled: false,
                    isOnly: false,
                    value: i
                });
            }

              // Load fields
            if(typeof this.config.fields !== 'undefined'){
              for (const i of this.config.fields) {
                this.fields.push({
                  display: i.display,
                  name: i.name,
                  value: null,
                  rules: i.rules ? i.rules : [],
                  items: i.items ? i.items : []
                });
              }
            }

            // Load none option
            if (typeof this.config.onlyOption === 'string' && this.config.onlyOption.length > 0) {
                this.options.push({
                    isDisabled: false,
                    isOnly: true,
                    value: this.config.onlyOption
                });

                //Store index of none option
                this.indexNone = this.options.length - 1;
            }

            // Set value if stored
            const regStore = this.$store.getters['registration/get'];
            if (typeof regStore[this.config.storeField] !== 'undefined') {
                for (const i of regStore[this.config.storeField]) {
                    const findIndex = _.findIndex(this.options, {value: i});
                    if (findIndex !== -1) {
                        this.selectOption(findIndex);
                        this.value.push(findIndex);
                    }
                }
            }

            if(typeof this.config.fields !== 'undefined') {
              for (const i of this.fields) {
                if (typeof regStore[`${this.config.storeField}_${i.name}`] !== 'undefined') {
                  i.value = regStore[`${this.config.storeField}_${i.name}`]
                }
              }
            }
        },
        mounted() {
            window.scrollTo(0, 0)
        },
        methods: {
            selectOption: function (i) {
                if (this.indexNone === i) {
                    if (this.value.indexOf(i) === -1) {
                        // Remove all items
                        this.value.splice(0, this.value.length);

                        // Disable all options except none
                        for (const option of this.options) {
                            if (option.isOnly === false) {
                                option.isDisabled = true;
                            }
                        }
                    } else {
                        // Disable all options
                        for (const option of this.options) {
                            if (option.isOnly === false) {
                                option.isDisabled = false;
                            }
                        }
                    }
                }
            },
            clickContinue: async function () {
              // Generate result
              let result = [];
              for (const i of this.value) {
                  result.push(this.options[i].value);
              }

              // Store result
              let payload = {};
              if(typeof this.fields !== 'undefined') {
                for (const i of this.fields) {
                  payload[`${this.config.storeField}_${i.name}`] = i.value;
                }
              }

              payload[this.config.storeField] = result;

              await this.$http.post('/save', {current:this.$store.getters['registration/get'],payload:payload})
              this.$store.commit('registration/update', payload);

              // Execute callback click
              await this.config.clickContinue(result);
            }
        }
    }
</script>
