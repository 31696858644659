<template>
    <date :config="config" :key="config.storeField"></date>
</template>
<script>
    import Date from '../template/Date'
    import moment from 'moment'

    export default {
        components: {Date},
        data: function () {
            return {
                config: {
                    question: 'When were you born?',
                    storeField: 'date_of_birth',
                    minDate: moment().subtract(100, 'years').format('YYYY-MM-DD'),
                    maxDate: moment().subtract(5, 'years').format('YYYY-MM-DD'),
                    birthdayPicker: true,
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function (value) {
                      // Check if flow is defined in config - if not, process as normal default registration
                      const response = await this.$http.post('/verifyFlow', {flow:this.flow})
                        if(response.data.nextStep !== null){
                          await this.$router.push(`/reg/${response.data.nextStep}`)
                        } else {
                          if (moment().diff(value, 'years') < 18) {
                            await this.$router.push('/reg/guardianContact')
                          } else {
                            await this.$router.push('/reg/emergencyContact')
                          }
                        }
                    }.bind(this)
                }
            }
        },
        beforeMount() {
          this.flow = this.$store.getters['registration/get'].flow
        },
    }
</script>