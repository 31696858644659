<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'What type of colon cancer screening did you have?',
        options: [
          'Colonoscopy',
          'Multi-target stool DNA test',
          'I do not remember'
        ],
        storeField: 'colon_cancer_screening_type',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/preventativeScreenings')
        }.bind(this)
      }
    }
  }
}
</script>
