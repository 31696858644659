<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'What is your Medicare Beneficiary Number (MBI)?',
        bypassText: 'I do not have a Medicare Beneficiary Number',
        helpText: 'Please note, this may be different from your insurance number',
        bypass:false,
        fields: [
          {
            display: 'Medicare Beneficiary Number (MBI)',
            name:'beneficiary_number',
            rules: [
              v => !!v || 'Required',
              v => (typeof v === 'string'
                  &&  /^([1-9])((?![SLOIBZ])[A-Z])((?![SLOIBZ])[A-Z|0-9])([0-9])((?![SLOIBZ])[A-Z])((?![SLOIBZ])[A-Z|0-9])([0-9])((?![SLOIBZ])[A-Z])((?![SLOIBZ])[A-Z])([0-9])([0-9])$/.test(v.replace(/[^A-Za-z0-9]/g,"").trim().toUpperCase()))
                  || 'Medicare Beneficiary Number must be valid'
            ]
          }
        ],

        storeField: 'medicare',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/consentMedicare')
        }.bind(this)
      }
    }
  }
}
</script>
