<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'Did you consider going to an Urgent Care or Emergency Room today?',
        options: [
          'Yes',
          'No'
        ],
        storeField: 'emergency_room',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/background')
        }.bind(this)
      }
    }
  }
}
</script>
