<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Over the last 2 weeks, how often have you been bothered by the following problems?',
        fields: [
          {
            display_long: 'Feeling nervous, anxious or on edge',
            name: 'gad_1',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Not being able to stop or control worrying',
            name: 'gad_2',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Little interest or pleasure in doing things',
            name: 'phq_1',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Feeling down, depressed or hopeless',
            name: 'phq_2',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'bh_background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function (value) {
          // Calculate GAD score
          const gad1 = this.items.indexOf(value.bh_background_gad_1)
          const gad2 = this.items.indexOf(value.bh_background_gad_2)
          const gad = gad1 + gad2;

          // Calculate PHQ score
          const phq1 = this.items.indexOf(value.bh_background_phq_1)
          const phq2 = this.items.indexOf(value.bh_background_phq_2)
          const phq = phq1 + phq2

          if (gad >= 3 || phq >= 3 || this.flow === 'bh') {
            await this.$router.push('/reg/bhBackgroundA')
          } else {
            await this.$router.push('/reg/Conditions')
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
    this.flow = this.$store.getters['registration/get'].flow
    this.items = [
      'Not at all',
      'Several days',
      'More than half the days',
      'Nearly every day'
    ]
  },
}
</script>
