<template>
  <date :config="config" :key="config.storeField"></date>
</template>
<script>
import Date from '../template/Date'
import moment from 'moment'

export default {
  components: {Date},
  data: function () {
    return {
      config: {
        question: 'Specified Individual Records Release:',
        helpText: 'When would you like your medical record release to expire?',
        storeField: 'medical_records_release_expiration_date',
        minDate: moment().format('YYYY-MM-DD'),
        maxDate: moment().add(10, 'years').format('YYYY-MM-DD'),
        birthdayPicker: true,
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/hasInsurance')
        }.bind(this)
      }
    }
  }
}
</script>