<template>
  <consent :config="config" :key="config.storeField"></consent>
</template>
<script>
import Consent from '../template/Consent';
import ConsentText from '../../assets/consents/privacyPractices.txt'

export default {
  components: {Consent},
  data: function () {
    return {
      config: {
        title: 'Notification of Privacy Practices',
        consentText: ConsentText,
        checkboxText: 'I agree to the Notification of Privacy Practices',
        storeField: 'accepted_consent_hipaa',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/consentAssignmentOfBenefits')
        }.bind(this)
      }
    }
  }
}
</script>
