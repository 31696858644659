<template>
    <consent :config="config" :key="config.storeField"></consent>
</template>
<script>
    import Consent from '../template/Consent';
    import ConsentText from '../../assets/consents/specimenCollection.txt'
    import moment from "moment";

    export default {
        components: {Consent},
        data: function () {
            return {
                config: {
                    title: 'Specimen Collection Authorization',
                    consentText: ConsentText,
                    checkboxText: 'I agree to the Specimen Collection Authorization',
                    storeField: 'accepted_consent_specimen_collection_authorization',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                      if(this.over65 === true){
                        await this.$router.push('/reg/medicareNumber')
                      } else {
                        await this.$router.push('/reg/medicalRecordsRelease')
                      }
                    }.bind(this)
                }
            }
        },
      beforeMount() {
        this.over65 = moment(this.$store.getters['registration/get'].date_of_birth).isSameOrBefore(moment().subtract(65,'years'))
      },
    }
</script>
