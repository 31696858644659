<template>
    <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
    import TextField from '../template/TextField';

    export default {
        components: {TextField},
        data: function () {
            return {
                config: {
                  question: 'Specified Individual Records Release:',
                  helpText: 'Please specify at least one individual - any extras can be left blank',
                  fields: [
                        {
                            display: 'Name',
                            name: 'name1',
                            rules: [
                                  v => !!v || 'Required',
                                  v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed',
                                  v => !v || (v.trim().split(' ').length > 1 && v.trim().split(' ').every((e) => e.length > 1) === true) || 'Please enter a first and last name'
                            ]
                        },
                        {
                            display: 'Relation',
                            name: 'relation1',
                            type: 'select',
                            items:   [
                              'Spouse',
                              'Child',
                              'Parent',
                              'Sibling',
                              'Friend',
                              'Life Partner',
                              'Guardian',
                              'Stepchild',
                              'Grandchild',
                              'Grandparent',
                              'Employee',
                              'Employer',
                              'Extended Family',
                              'Other'
                            ],
                            rules: [
                              v => !!v || 'Required',
                            ]
                        },
                      {
                        display: 'Name',
                        name: 'name2',
                        rules: [
                          v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed',
                          v => !v || (v.trim().split(' ').length > 1 && v.trim().split(' ').every((e) => e.length > 1) === true) || 'Please enter a first and last name'
                        ]
                      },
                      {
                        display: 'Relation',
                        name: 'relation2',
                        type: 'select',
                        items:   [
                          'Spouse',
                          'Child',
                          'Parent',
                          'Sibling',
                          'Friend',
                          'Life Partner',
                          'Guardian',
                          'Stepchild',
                          'Grandchild',
                          'Grandparent',
                          'Employee',
                          'Employer',
                          'Extended Family',
                          'Other'
                        ],
                        rules: []
                      },
                      {
                        display: 'Name',
                        name: 'name3',
                        rules: [
                          v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed',
                          v => !v || (v.trim().split(' ').length > 1 && v.trim().split(' ').every((e) => e.length > 1) === true) || 'Please enter a first and last name'
                        ]
                      },
                      {
                        display: 'Relation',
                        name: 'relation3',
                        type: 'select',
                        items:   [
                          'Spouse',
                          'Child',
                          'Parent',
                          'Sibling',
                          'Friend',
                          'Life Partner',
                          'Guardian',
                          'Stepchild',
                          'Grandchild',
                          'Grandparent',
                          'Employee',
                          'Employer',
                          'Extended Family',
                          'Other'
                        ],
                        rules: [
                        ]
                      },
                    ],
                    storeField: 'medical_records_release',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/medicalRecordsReleaseStep2')
                    }.bind(this)
                }
            }
        }
    }
</script>
