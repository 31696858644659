<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'What is your marital status?',
        options: [
          'Single',
          'Married',
          'Separated',
          'Widowed',
          'Divorced',
          'Unknown'
        ],
        storeField: 'marital_status',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/employer')
        }.bind(this)
      }
    }
  }
}
</script>
