<template>
    <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
    import TextField from '../template/TextField';

    export default {
        components: {TextField},
        data: function () {
            return {
                config: {
                    question: 'What is your email?',
                    fields: [
                        {
                            display: 'Email',
                            name: 'email',
                            rules: [
                                v => !!v || 'Required',
                                v => (typeof v === 'string' && /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(v.trim())) || 'Email must be valid'
                            ]
                        }
                    ],
                    storeField: 'email',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/dateOfBirth')
                    }.bind(this)
                }
            }
        }
    }
</script>
