<template>
    <consent :config="config" :key="config.storeField"></consent>
</template>
<script>
    import Consent from '../template/Consent';
    import ConsentText from '../../assets/consents/electronicSignature.txt'

    export default {
        components: {Consent},
        data: function () {
            return {
                config: {
                    title: 'Electronic Signature Consent',
                    consentText: ConsentText,
                    checkboxText: 'I agree to use electronic records and signatures',
                    storeField: 'accepted_consent_electronic_signature',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/consentPrivacyPractices')
                    }.bind(this)
                }
            }
        }
    }
</script>
