<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Answer the following...',
        fields: [
          {
            display_long: 'Have you had any thoughts about killing yourself?',
            name: 'cssrs_2',
            type: 'select',
            items: [
              'No',
              'Yes - within the past month'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'bh_background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function (value) {
          if (value.bh_background_cssrs_2 === 'Yes - within the past month') {
            await this.$router.push('/reg/bhBackgroundAssessment2')
          } else {
            // Submit flow payload if we are in valid flow
            if (this.flow) {
              // Submit payload
              await this.$http.post('/registration', this.registration)
              this.$store.commit('registration/clear')
              this.$router.push({path: '/reg/complete', query: {f: this.flow}})
            } else {
              await this.$router.push('/reg/Conditions')
            }
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
    this.flow = this.$store.getters['registration/get'].flow
    this.registration = this.$store.getters['registration/get']
  },
}
</script>
