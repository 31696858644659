<template>
    <consent :config="config" :key="config.storeField"></consent>
</template>
<script>
    import Consent from '../template/Consent';
    import ConsentText from '../../assets/consents/financialPolicy.txt'

    export default {
        components: {Consent},
        data: function () {
            return {
                config: {
                    title: 'Financial Policy',
                    consentText: ConsentText,
                    checkboxText: 'I agree to the Financial Policy',
                    storeField: 'accepted_consent_financial_policy',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/consentPatientTreatmentContract')
                    }.bind(this)
                }
            }
        }
    }
</script>
