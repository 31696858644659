<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'Specified Individual Records Release:',
        helpText: 'What are your desired terms of expiration for this medical records release?',
        options: [
          'Indefinitely',
          'I would like to specify a date of expiration'
        ],
        storeField: 'medical_records_release_expiration',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function (value) {
          if (value === 'Indefinitely') {
            await this.$router.push('/reg/hasInsurance')
          } else {
            await this.$router.push('/reg/medicalRecordsReleaseStep5')
          }
        }.bind(this)
      }
    }
  }
}
</script>
