<template>
    <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
    import TextField from '../template/TextField';
    import PhoneNumber from "awesome-phonenumber";

    export default {
        components: {TextField},
        data: function () {
            return {
                config: {
                    question: 'Who is your parent or legal guardian contact?',
                    helpText: 'Legal guardian or parent MUST be completing registration!',
                    fields: [
                        {
                            display: 'Name',
                            name: 'name',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed',
                                v => !v || (v.trim().split(' ').length > 1 && v.trim().split(' ').every((e) => e.length > 1) === true) || 'Please enter a first and last name'
                            ]
                        },
                        {
                            display: 'Phone Number',
                            name: 'phone',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || ((PhoneNumber(v, 'US').isValid() === true) && (PhoneNumber(v, 'US').isPossible() === true)) || 'Please enter a valid phone number'
                            ]
                        },
                        {
                            display: 'Relation',
                            name: 'relation',
                            type: 'select',
                            items: [
                                'Guardian',
                                'Grandparent',
                                'Parent',
                                'Other'
                            ],
                            rules: [
                                v => !!v || 'Required',
                            ]
                        }
                    ],
                    storeField: 'guardian_contact',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/homeAddress')
                    }.bind(this)
                }
            }
        }
    }
</script>
