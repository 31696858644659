<template>
    <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
    import SingleSelect from '../template/SingleSelect';

    export default {
        components: {SingleSelect},
        data: function () {
            return {
                config: {
                  question: 'Specified Individual Records Release:',
                  helpText: 'Do you want to authorize release of medical records to specified family members or other individuals?',
                  options: [
                        'Yes',
                        'No'
                    ],
                    storeField: 'medical_records_release',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function (value) {
                        if (value === 'Yes') {
                            await this.$router.push('/reg/medicalRecordsReleaseStep1')
                        } else {
                          await this.$router.push('/reg/hasInsurance')
                        }
                    }.bind(this)
                }
            }
        }
    }
</script>
