<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';
import moment from "moment/moment";

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'When was your last mammogram?',
        options: [
          'Less than two years ago',
          'More than two years ago',
          'I do not remember',
          'Never'
        ],
        storeField: 'mammogram',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          if (this.age >= 30 && this.gender === 'Other') {
            await this.$router.push('/reg/trt')
          } else {
            await this.$router.push('/reg/consentElectronicSignature')
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
  },
}
</script>
