<template>
  <div>
    <v-form v-model="isValid" @submit.prevent="">
      <v-row>
        <v-col>
          <h3 class="font-weight-bold">Did you receive the verification pin we sent?</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>We sent a 6 digit pin to you at {{ phone }}</h4>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field :label="Pin" v-model="pin" :rules="this.rules" placeholder="Verification Pin"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" order="last">
          <v-btn color="primary" v-on:click.stop="clickContinue()" :disabled="!isValid">
            Continue
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col order="first">
          <v-btn v-on:click.stop="clickBack()">
            Back
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasError === true">
        <v-col>
          <h4 class="red--text">The verification pin you have entered is either incorrect, or has expired. Please try
            again, or go back to the previous page to send another pin.</h4>
        </v-col>
      </v-row>
      <v-row v-if="maxAttempts === true">
        <v-col>
          <h4 class="red--text">You have reached the maximum attempts for verification - wait 10 minutes and try again,
            or
            please fill out the new patient kiosk in the lobby</h4>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber';

export default {
  name: 'verify',
  data: function () {
    return {
      fields: [],
      isValid: false,
      maxAttempts: false,
      hasError: false,
      phone: null,
      pin: '',
      rules: [
        v => !!v || 'Required',
        v => !v || ((typeof v === 'string' && /^\d{6}$/.test(v.trim()))) || 'Please enter 6 digit pin'
      ]
    }
  },
  props: ['config'],
  beforeMount() {
    this.phone = PhoneNumber(this.$store.getters['registration/get'].phone_phone, 'US').getNumber()
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    clickContinue: async function () {
      // Execute callback click
      try {
        await this.$http.post('/verifyPin', {
          pin: this.pin,
          phone: this.phone
        })
        await this.$router.push('/reg/email')
      } catch (e) {
        if (e.response.status === 429) {
          this.maxAttempts = true
          this.hasError = false
        } else {
          this.hasError = true
        }
      }
    },
    clickBack: function () {
      this.$router.go(-1)
    }
  }
}
</script>
