<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Employer',
        fields: [
          {
            display: 'Who is your employer?',
            name: 'employer',
            rules: [
              v => !v || v.length <= 255 || 'Maximum of 255 characters is allowed',
            ]
          }
        ],
        storeField: 'employer',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/homeAddress')
        }.bind(this)
      }
    }
  }
}
</script>
