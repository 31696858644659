<template>
    <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
    import SingleSelect from '../template/SingleSelect';

    export default {
        components: {SingleSelect},
        data: function () {
            return {
                config: {
                    question: 'Do you have health insurance?',
                    options: [
                        'Yes',
                        'No'
                    ],
                    storeField: 'has_insurance',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function (value) {
                        if (value === 'Yes') {
                            await this.$router.push('/reg/insurancePhotos')
                        } else {
                            await this.$router.push('/reg/identificationPhotos')
                        }
                    }.bind(this)
                }
            }
        }
    }
</script>
