<template>
    <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
    import TextField from '../template/TextField';

    export default {
        components: {TextField},
        data: function () {
            return {
                config: {
                    question: 'What is your full name?',
                    fields: [
                        {
                            display: 'First Name',
                            name: 'first',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed'
                            ]
                        },
                        {
                            display: 'Last Name',
                            name: 'last',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed'
                            ]
                        }
                    ],
                    storeField: 'name',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/phone')
                    }.bind(this)
                }
            }
        }
    }
</script>
