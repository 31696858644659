<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Over the last 2 weeks, how often have you been bothered by the following problems?',
        fields: [
          {
            display_long: 'Worrying too much about different things',
            name: 'gad_3',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Trouble relaxing',
            name: 'gad_4',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Being so restless that it is hard to sit still',
            name: 'gad_5',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Becoming easily annoyed or irritable',
            name: 'gad_6',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Feeling afraid as if something awful might happen',
            name: 'gad_7',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'If you selected any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with other people?',
            name: 'gad_difficulty',
            type: 'select',
            items: [
              'Not difficult at all',
              'Somewhat difficult',
              'Very difficult',
              'Extremely difficult'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'bh_background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/bhBackgroundB')
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
    this.full_phq = this.$store.getters['registration/get'].full_phq
  },
}
</script>
