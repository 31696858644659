<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'Are you interested in Testosterone Replacement Therapy?',
        options: [
          'Yes',
          'No'
        ],
        storeField: 'trt',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/consentElectronicSignature')
        }.bind(this)
      }
    }
  }
}
</script>