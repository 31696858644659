<template>
    <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
    import TextField from '../template/TextField';

    export default {
        components: {TextField},
        data: function () {
            return {
                config: {
                    question: 'Where do you live?',
                    helpText: 'Dont worry about including the city and state in your address',
                    fields: [
                        {
                            display: 'Address',
                            name: 'address',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed',
                                v => !v || (v.trim().split(' ').length > 1 && v.trim().split(' ').every((e) => e.length > 0) === true) || 'Please enter valid address'
                            ]
                        },
                        {
                            display: 'Zip Code',
                            name: 'zip_code',
                            rules: [
                                v => !!v || 'Required',
                                v => /^\d{5}(?:[-\s]\d{4})?$/.test(v) || 'Zip Code must be valid'
                            ]
                        }
                    ],
                    storeField: 'address',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/gender')
                    }.bind(this)
                }
            }
        }
    }
</script>
