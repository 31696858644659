<template>
    <consent :config="config" :key="config.storeField"></consent>
</template>
<script>
    import Consent from '../template/Consent';
    import ConsentText from '../../assets/consents/assignmentOfBenefits.txt'

    export default {
        components: {Consent},
        data: function () {
            return {
                config: {
                    title: 'Notice to Patients',
                    consentText: ConsentText,
                    checkboxText: 'I agree to the Notice to Patients',
                    storeField: 'accepted_consent_assignment_of_benefits',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/consentFinancialPolicy')
                    }.bind(this)
                }
            }
        }
    }
</script>
