<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Additional Background Information',
        fields: [
          {
            display_long: 'Do you snore loudly?',
            name: 'snore',
            type: 'select',
            items: [
              'Yes',
              'No'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Do you often feel tired, fatigued, or sleepy during the daytime?',
            name: 'tired',
            type: 'select',
            items: [
              'Yes',
              'No'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Has anyone observed you stop breathing or choking/gasping during your sleep?',
            name: 'observed',
            type: 'select',
            items: [
              'Yes',
              'No'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'ss_background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function () {
          await this.$router.push('/reg/bhBackground')
        }.bind(this)
      }
    }
  },
  beforeMount() {
  },
}
</script>
