<template>
    <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
    import SingleSelect from '../template/SingleSelect';

    export default {
        components: {SingleSelect},
        data: function () {
            return {
                config: {
                  question: 'Specified Individual Records Release:',
                  helpText: 'Do you want to authorize disclosure of your complete health record (including but not limited to diagnoses, lab tests,\n' +
                      'prognosis, treatment, and billing, for all conditions)?',
                  options: [
                        'Yes',
                        'No, I would like to specify restrictions'
                    ],
                    storeField: 'medical_records_release_all',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function (value) {
                        if (value === 'Yes') {
                            await this.$router.push('/reg/medicalRecordsReleaseStep4')
                        } else {
                          await this.$router.push('/reg/medicalRecordsReleaseStep3')
                        }
                    }.bind(this)
                }
            }
        }
    }
</script>
