<template>
    <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
    import TextField from '../template/TextField';

    export default {
        components: {TextField},
        data: function () {
            return {
                config: {
                    question: 'Please enter your insurance details',
                    fields: [
                        {
                            display: 'Insurance Company',
                            name: 'name',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed'
                            ]
                        },
                        {
                            display: 'Member ID',
                            name: 'id',
                            rules: [
                                v => !!v || 'Required',
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed'
                            ]
                        },
                        {
                            display: 'Group Number (Optional)',
                            name: 'group',
                            rules: [
                                v => !v || v.length <= 50 || 'Maximum of 50 characters is allowed'
                            ]
                        }
                    ],
                    storeField: 'insurance',
                    clickBack: function () {
                        this.$router.go(-1)
                    }.bind(this),
                    clickContinue: async function () {
                        await this.$router.push('/reg/identificationPhotos')
                    }.bind(this)
                }
            }
        }
    }
</script>
