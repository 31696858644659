<template>
  <single-select :config="config" :key="config.storeField"></single-select>
</template>
<script>
import SingleSelect from '../template/SingleSelect';

export default {
  components: {SingleSelect},
  data: function () {
    return {
      config: {
        question: 'When was your last colon cancer screening?',
        options: [
          'Less than three years ago',
          'More than three years ago',
          'I do not remember',
          'Never'
        ],
        storeField: 'last_colon_cancer_screening',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function (value) {
          if (['I do not remember','Never'].indexOf(value) === -1) {
            await this.$router.push('/reg/colonCancerScreeningType')
          } else {
            await this.$router.push('/reg/preventativeScreenings')
          }
        }.bind(this)
      }
    }
  }
}
</script>
