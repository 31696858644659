<template>
  <text-field :config="config" :key="config.storeField"></text-field>
</template>
<script>
import TextField from '../template/TextField';
import moment from "moment/moment";

export default {
  components: {TextField},
  data: function () {
    return {
      config: {
        question: 'Over the last 2 weeks, how often have you been bothered by the following problems?',
        fields: [
          {
            display_long: 'Trouble falling asleep, staying asleep, or sleeping too much',
            name: 'phq_3',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Feeling tired or having little energy',
            name: 'phq_4',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Poor appetite or overeating',
            name: 'phq_5',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: `Feeling bad about yourself - or that you're a failure or have let yourself or your family down`,
            name: 'phq_6',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Trouble concentrating on things, such as reading the newspaper or watching television',
            name: 'phq_7',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
            name: 'phq_8',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'Thoughts that you would be better off dead or of hurting yourself in some way',
            name: 'phq_9',
            type: 'select',
            items: [
              'Not at all',
              'Several days',
              'More than half the days',
              'Nearly every day'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          },
          {
            display_long: 'If you selected any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with other people?',
            name: 'phq_difficulty',
            type: 'select',
            items: [
              'Not difficult at all',
              'Somewhat difficult',
              'Very difficult',
              'Extremely difficult'
            ],
            rules: [
              v => !!v || 'Required'
            ]
          }
        ],
        storeField: 'bh_background',
        clickBack: function () {
          this.$router.go(-1)
        }.bind(this),
        clickContinue: async function (value) {
          // Trigger crisis notification based on phq9 question 9
          if (value.bh_background_phq_9 !== 'Not at all') {
            await this.$http.post('/sendCrisisNotification', {payload: this.registration})
            await this.$router.push('/reg/bhBackgroundAssessment1')
          } else {
            // Submit flow payload if we are in valid flow
            if (this.flow) {
              // Submit payload
              await this.$http.post('/registration', this.registration)
              this.$store.commit('registration/clear')
              this.$router.push({path: '/reg/complete', query: {f: this.flow}})
            } else {
              await this.$router.push('/reg/Conditions')
            }
          }
        }.bind(this)
      }
    }
  },
  beforeMount() {
    this.age = moment().diff(moment(this.$store.getters['registration/get'].date_of_birth), 'y')
    this.gender = this.$store.getters['registration/get'].gender
    this.flow = this.$store.getters['registration/get'].flow
    this.registration = this.$store.getters['registration/get']
  },
}
</script>
